import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {KeycloakService} from 'keycloak-angular';
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private uri;
  private authTokenKey;
  protected httpOptions: { headers: HttpHeaders };

  constructor(
    private http: HttpClient,
    protected keycloakService: KeycloakService) {
    this.uri = environment.apiUri;
    this.authTokenKey = environment.authTokenKey;
  }

  private validToken() {
    return this.keycloakService.isLoggedIn();
  }

  private prepareHttpHeaders(uuidAccountPayment?: string, extras?: ExtraHeader[]) {
    //  console.log('prepareHttpHeaders::zero::', uuidAccountPayment, extras);
    let userToken = '';
    this.getToken().then(token => {
      userToken = token;
    });

    const httpOptions = {
      headers: new HttpHeaders({
        'X-Request-UAC': uuidAccountPayment ? uuidAccountPayment : '',
      }),
    };

    httpOptions.headers = httpOptions.headers.append('Authorization', `Bearer ${userToken}`);

    //   console.log('prepareHttpHeaders::primeiro::', httpOptions);
    if (extras) {
      for (const extra of extras) {
        httpOptions.headers = httpOptions.headers.append(extra.name, extra.value);
      }
    }

    //  console.log('prepareHttpHeaders::segundo::', httpOptions);

    return httpOptions;
  }

  private getToken() {
    return this.keycloakService.getToken();
  }

  get(path: string, extras?: ExtraHeader[]): Observable<any> {
    const httpOptions = this.prepareHttpHeaders(undefined, extras);
    return this.http.get<any>(this.uri + path, httpOptions);
  }

  getBlob(path: string): Observable<Blob> {
    let userToken = '';
     this.getToken().then(token => {
      userToken = token;
    });

    return this.http.get(this.uri + path, {
      headers: new HttpHeaders({ 'Authorization': `Bearer ${userToken}`}),
      responseType: 'blob'
    });
  }

  postBlob(path: string, dateReport: any): Observable<Blob> {
    let userToken = '';
     this.getToken().then(token => {
      userToken = token;
    });

    return this.http.post(this.uri + path, dateReport, {
      headers: new HttpHeaders({'Authorization': `Bearer ${userToken}`}),
      responseType: 'blob'
    });
  }

  put(path: string, body: Object, uuidAccountPayment?: string, extras?: ExtraHeader[]): Observable<any> {
    const httpOptions = this.prepareHttpHeaders(uuidAccountPayment, extras);
    return this.http.put(this.uri + path, body, httpOptions);
  }

  post(path: string, body?: Object, uuidAccountPayment?: string, extras?: ExtraHeader[]): Observable<any> {
    const httpOptions = this.prepareHttpHeaders(uuidAccountPayment, extras);
    const uri = this.uri + path;
    return this.http.post(uri, body, httpOptions);
  }

  delete(path: string, uuidAccountPayment?: string, extras?: ExtraHeader[]): Observable<any> {
    const httpOptions = this.prepareHttpHeaders(uuidAccountPayment, extras);
    return this.http.delete(this.uri + path, httpOptions);
  }

}

export class ExtraHeader {
  name: string;
  value: string;
}
