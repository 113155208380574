import { Observable } from 'rxjs';
import { Company } from './../../pages/admin/companies/services-apis/companies.model';
import { Injectable } from "@angular/core";
import { ApiService } from "../_base/_auth/api.service";
import { Employee } from 'src/app/pages/admin/employees/services-apis/employees-model';
import { environment } from 'src/environments/environment';

const SPE_N_BACKOFFICE_CONSIGNED = '/spe-n-backoffice-consigned';

@Injectable({
  providedIn: 'root'
})

export class SpeNBackofficeConsignedProxy {

  constructor(private api: ApiService) { }

  /* Company */
  createCompany(company: Company) {
    const uri = `${SPE_N_BACKOFFICE_CONSIGNED}/v1/company/create/`;
    return this.api.post(uri, company);
  };

  editCompany(company: Company) {
    const uri = `${SPE_N_BACKOFFICE_CONSIGNED}/v1/company/update/`;
    return this.api.put(uri, company);
  };

  /* Employee */
  createEmployee(employee: Employee, uuidwl: string): Observable<any> {
    const uri = `${SPE_N_BACKOFFICE_CONSIGNED}/v1/employees/create/${uuidwl}`;
    return this.api.post(uri, employee);
  };

  editEmployee(employee: Employee) {
    const uri = `${SPE_N_BACKOFFICE_CONSIGNED}/v1/employees/update/`;
    return this.api.put(uri, employee);
  };

  deleteEmployee(uuid: string) {
    const uri = `${SPE_N_BACKOFFICE_CONSIGNED}/v1/employees/delete/${uuid}`;
    return this.api.delete(uri);
  }


  aproveProposal(uuid: string, user: string, status: string, name: string, email: string, latitude: string, longitude?: string, device?: string, aproveEmail?: string, description?: string): Observable<any> {
    const uri = `${SPE_N_BACKOFFICE_CONSIGNED}/v1/proposals/aprove/${uuid}/${user}/${status}`;
    return this.api.put(uri, {
      name,
      email,
      latitude,
      longitude,
      device,
      aproveEmail,
      description
    }, user);
  }

  /* Simulation */
  simulation(uuid: string, value: any, hasInsurance: boolean): Observable<any> {
    let body = {
      uuid,
      value,
      hasInsurance
    }
    const uri = `${SPE_N_BACKOFFICE_CONSIGNED}/v1/simulation`;
    return this.api.post(uri, body);
  }

  simulationEspecial(uuid: string, value: any, hasInsurance: boolean, rateInterest: number, maxInstallment: number, dateFirstInstallment: string, tac: number, description: string, customerName:string): Observable<any> {
    let body = {
      uuid,
      uuidWhiteLabel: environment.wlp,
      value,
      hasInsurance,
      rateInterest,
      maxInstallment,
      dateFirstInstallment,
      tac,
      description,
      customerName
    }
    const uri = `${SPE_N_BACKOFFICE_CONSIGNED}/v1/special-calculation`;
    return this.api.post(uri, body);
  }


  generateProposal(id: any, installmentNumber: number): Observable<any> {
    const uri = `${SPE_N_BACKOFFICE_CONSIGNED}/v1/proposals/generate/${id}/${installmentNumber}`;
    return this.api.post(uri, {});
  }

  uploadEmployees(body: any) {
    const uri = `${SPE_N_BACKOFFICE_CONSIGNED}/v1/importation/employee/`;
    return this.api.post(uri, body)
  }

  /* Contract */
  paycontract(uuid: any, email: string): Observable<any> {
    const uri = `${SPE_N_BACKOFFICE_CONSIGNED}/v1/contract/paycontract/${uuid}`;
    return this.api.put(uri, { email });
  }

  /* Invoice */
  generetedInvoice(uuidInvoice: string, emailUser: string) {
    const uri = `${SPE_N_BACKOFFICE_CONSIGNED}/v1/invoices/generate/${uuidInvoice}/user/${emailUser}`;
    return this.api.put(uri, {});
  }

  deleteInvoice(uuid: string) {
    const uri = `${SPE_N_BACKOFFICE_CONSIGNED}/v1/invoices/delete/${uuid}`;
    return this.api.put(uri, {});
  }


  approveInvoice(uuidInvoice: string, auditEmail: string) {
    const uri = `${SPE_N_BACKOFFICE_CONSIGNED}/v1/invoices/${uuidInvoice}/approve/${auditEmail}`;
    return this.api.put(uri, {});
  }

  depositedInvoice(uuidInvoice: string, masterEmail: string) {
    const uri = `${SPE_N_BACKOFFICE_CONSIGNED}/v1/invoices/${uuidInvoice}/deposited/${masterEmail}`;
    return this.api.put(uri, {});
  }

  paymantContracts(contracts: string[], email: string) {

    let contractlist = {
      listIDContracts: contracts,
      email
    };
    const uri = `${SPE_N_BACKOFFICE_CONSIGNED}/v1/contract/paycontract/multiple/paymants`;
    return this.api.put(uri, contractlist);

  }

  generatePreProposal(id: string): Observable<any> {
    const uri = `${SPE_N_BACKOFFICE_CONSIGNED}/v1/proposals/update/status/${id}`;
    return this.api.put(uri, {});
  }
  
  retrySendContract(uuidProposal: string): Observable<any> {
    const uri = `${SPE_N_BACKOFFICE_CONSIGNED}/v1/contract/retry-send-contract/partner/${uuidProposal}`;
    return this.api.put(uri, {});
  }

}
